#login .container_ #login-row #login-column #login-box {
    margin-top: 20px;
    /* max-width: 500px; */
    border: 1px solid #9C9C9C;
    background-color: #EAEAEA;
    border-radius: 7px;
  }
  #login .container_ #login-row #login-column #login-box #login-form {
    padding: 20px;
  }
  #login .container_ #login-row #login-column #login-box #login-form #register-link {
    margin-top: -85px;
  }
  #login {
    background-color: #d1d1d1;
    height: 800px;
  }
  label{
    padding: 4px;
  }
  .text-info{
    color: #2d78c1 !important;
  }
  .sign-up .form-group{
    
  }
  .imggolgo{
    height: 76px;
    margin: 2% 25%;
    border: 2px solid #55be2a;
    border-radius: 20px;
  }