/*

Template: COINEX - Crypto Currency HTML Template 
Author: iqonicthemes.in
Version: 3
Design and Developed by: iqonicthemes.in

NOTE: This is main stylesheet of template, This file contains the styling for the actual Template. Please do not change anything here! write in a custom.css file if required!
*/

/*================================================
[  Table of contents  ]
================================================

==> General
==> Back to Top
==> Loader
==> Buttons
==> Page Section Margin Padding
==> Text Weight
==> Text Color 
==> Font Size 
==> Background Color
==> BG Effect
==> Background Gradient 
==> Section Title
==> Header
==> Banner
==> Clients
==> Fancy-box 
==> Tranding Platform
==> Team
==> Executive Partners
==> Blog
==> FAQ
==> Contact us
==> Owl Carousel

======================================
[ End table content ]
======================================*/

/*---------------------------------------------------------------------
                               General
-----------------------------------------------------------------------*/
*::-moz-selection { background: #f560b9; color: #fff; text-shadow: none; }
::-moz-selection { background: #f560b9; color: #fff; text-shadow: none; }
::selection { background: #f560b9; color: #fff; text-shadow: none; }
body { font-family: 'Comfortaa', cursive; font-weight: normal; position: relative; font-style: normal; font-size: 14px; line-height: 26px; color: #e3e3e3; overflow-x: hidden; background: #000000; }
a:hover, a:focus, a.active { text-decoration: none; outline: none; }
a, a:hover, a:focus { color: #f560b9; -webkit-transition: all 250ms ease-in-out 0s; -moz-transition: all 250ms ease-in-out 0s; -ms-transition: all 250ms ease-in-out 0s; -o-transition: all 250ms ease-in-out 0s; transition: all 250ms ease-in-out 0s; text-decoration: none; }
h1, h2, h3, h4, h5, h6 { font-family: 'Montserrat', sans-serif; font-weight: normal; color: #fff; margin-top: 0px; margin-bottom: 0px; }
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a { color: inherit; }
h1 { font-size: 50px; line-height: normal; }
h2 { font-size: 36px; line-height: normal; }
h3 { font-size: 32px; line-height: normal; }
h4 { font-size: 28px; line-height: normal; }
h5 { font-size: 24px; line-height: normal; }
h6 { font-size: 20px; line-height: normal; }
.lead { font-size: 21px; line-height: 32px; margin: 0; }
ul { margin: 0px; padding: 0px; }
li { list-style: none; }
hr { margin: 0; padding: 0px; border-bottom: 1px solid #e0e0e0; border-top: 0px; }
.blockquote { border-left: 5px solid #16181c; font-size: 16px; }
.container { max-width: 1170px; }

/*---------------------------------------------------------------------
                               Back to Top
-----------------------------------------------------------------------*/
#back-to-top .top { z-index: 999; position: fixed; margin: 0px; color: #fff; background: #121316; position: fixed; bottom: 25px; right: 25px; z-index: 999; font-size: 26px; width: 50px; height: 50px; text-align: center; line-height: 50px; border-radius: 90px; -webkit-transition: all .3s ease-in-out; -moz-transition: all .3s ease-in-out; transition: all .3s ease-in-out; }
#back-to-top .top:hover { background: #f560b9; color: #fff; -webkit-box-shadow: 0px 0px 30px 0px rgba(34, 34, 34, 0.1); -moz-box-shadow: 0px 0px 30px 0px rgba(34, 34, 34, 0.1); box-shadow: 0px 0px 30px 0px rgba(34, 34, 34, 0.1); }

/*---------------------------------------------------------------------
                                Loader
-----------------------------------------------------------------------*/
#loading { background-color: #16181c; height: 100%; width: 100%; position: fixed; margin-top: 0px; top: 0px; left: 0px; bottom: 0px; overflow: hidden !important; right: 0px; z-index: 999999; }
#loading-center { position: absolute; left: 50%; top: 50%; margin-left: -75px; margin-top: -75px; }

/*loader 2  */
.loader { position: relative; width: 150px; height: 150px; display: block; overflow: hidden; }
.loader div { height: 100%; }
.loader2, .loader2 div { border-radius: 50%; padding: 8px; border: 2px solid transparent; -webkit-animation: rotate linear 3.5s infinite; animation: rotate linear 3.5s infinite; border-top-color: rgba(245, 96, 185, 1); border-left-color: rgba(66, 66, 66, 1); border-right-color: rgba(249, 128, 200, 1); }
.loader, .loader * { will-change: transform; }
@-webkit-keyframes rotate {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  50% { -webkit-transform: rotate(180deg); transform: rotate(180deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes rotate {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  50% { -webkit-transform: rotate(180deg); transform: rotate(180deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@-webkit-keyframes rotate2 {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); border-top-color: rgba(0, 0, 0, 0.5); }
  50% { -webkit-transform: rotate(180deg); transform: rotate(180deg); border-top-color: rgba(0, 0, 255, 0.5); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); border-top-color: rgba(0, 0, 0, 0.5); }
}
@keyframes rotate2 {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); border-top-color: rgba(0, 0, 0, 0.5); }
  50% { -webkit-transform: rotate(180deg); transform: rotate(180deg); border-top-color: rgba(0, 0, 255, 0.5); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); border-top-color: rgba(0, 0, 0, 0.5); }
}
.rightNav {
  margin:0px;
  display:flex;
  align-items: center;
}
.rightNav > .material-icons {
  font-size:60px;
  margin-right:2px;
  opacity:0.8;
}
.myAccountBox > .address, .eth, .dctoken {
  font-size:12px;
}

/*----------------------------------------------------------------------
                        Buttons
-----------------------------------------------------------------------*/
.button { color: #fff; cursor: pointer; padding: 10px 30px; border: none; font-weight: 800; font-size: 16px; position: relative; background: #f560b9; font-family: 'Comfortaa', cursive; display: inline-block; -webkit-border-radius: 90px; -moz-border-radius: 90px; border-radius: 90px; }
.button:hover, .button:focus { color: #131519; background: #fff; outline: none; }

.buttons { color: #fff; cursor: pointer; margin-left:40%; padding: 11px 77px; border: none; font-weight: 800; font-size: 16px; position: relative; background: #4bbb1f; font-family: 'Comfortaa', cursive; display: inline-block; -webkit-border-radius: 90px; -moz-border-radius: 90px; border-radius: 90px; }
.buttons:hover, .buttons:focus { color: #131519; background: #fff; outline: none; }


/* Buttons white */
.button.bt-white { color: #333; background: #fff; }
.button.bt-white:hover, .button.bt-white:focus { color: #ffffff; background: #4bbb1f; outline: none; }

/* Buttons white 2 */
.button.bt-black { color: #333; background: #fff; }
.button.bt-black:hover, .button.bt-black:focus { color: #ffffff; background: #23292c; outline: none; }

/* Buttons Play */
.button-play { position: relative; z-index: 1; width: 65px; height: 65px; line-height: 68px; font-size: 18px; text-align: center; display: inline-block; color: #fff; background: #f9b707; border-radius: 50%; border: none; transition: box-shadow 400ms cubic-bezier(0.2, 0, 0.7, 1), -webkit-transform 200ms cubic-bezier(0.2, 0, 0.7, 1); transition: box-shadow 400ms cubic-bezier(0.2, 0, 0.7, 1), transform 200ms cubic-bezier(0.2, 0, 0.7, 1); transition: box-shadow 400ms cubic-bezier(0.2, 0, 0.7, 1), transform 200ms cubic-bezier(0.2, 0, 0.7, 1), -webkit-transform 200ms cubic-bezier(0.2, 0, 0.7, 1); }
.button-play i { padding-left: 5px; }
.button-play:hover, .button-play:focus { box-shadow: 0 0 1px 15px rgba(249, 183, 7, 0.4), 0 0 1px 30px rgba(249, 183, 7, 0.1), 0 0 1px 45px rgba(249, 183, 7, 0.1); color: #fff; }

/*---------------------------------------------------------------------
                    Page Section Margin Padding 
---------------------------------------------------------------------*/

/*----------------
Section padding 
----------------*/
h2, p{
  color: white;
}
.pull-right{
  float: right;
}
#page p{
  color: #000000 !important;
}
#page{
  /* height: 550px; */
}

.overview-block-ptb { padding: 100px 0;/* background-color: #081901;*/ 
  background-image: url("../images/futuristic-technology-BG.jpg"); 
  background-size: cover; background-repeat: no-repeat; }
.overview-block-pt { padding: 100px 0 0; background-color: #000000;}
.overview-block-pb { padding: 0 0 100px;/* background-color: #1e5f04*/
  background-image: url("../images/Technology-Background.jpg");
   background-size: cover; background-repeat: no-repeat;; }

.heading-titles{
 margin-bottom: 11px;
    /* text-align: center; */
    padding-top: 18px;
}
.card-title{
	margin-top: 20px;
}
.card-text{
	font-size: 12px;
}
.FEATUR_sect{
	margin-left: 107px;
}
.sect{
	margin-top: 28px;
}

@media (max-width: 480px) {
	
	.FEATUR_sect{
		margin-left: 0px;
	}        
	.imgs-cont{
		display: block;
		margin-left: auto;
		margin-right: auto;
		}
	.card-title{
		text-align: center;
	}
	.card-text{
		text-align: center;
	}
       }
@media (min-width: 481px) and (max-width: 767px) {
           
	.FEATUR_sect{
		margin-left: 0px;
	}        
	.imgs-cont{
		display: block;
		margin-left: auto;
		margin-right: auto;
		}
	.card-title{
		text-align: center;
	}
	.card-text{
		text-align: center;
	}
        	}
@media (min-width: 768px) and (max-width: 1024px){
            
	.FEATUR_sect{
		margin-left: 0px;
	}        
	.imgs-cont{
		display: block;
		margin-left: auto;
		margin-right: auto;
		}
	.card-title{
		text-align: center;
	}
	.card-text{
		text-align: center;
	}
        }

/*----------------
Padding All 
----------------*/
.iq-pall { padding: 0px !important; }
.iq-pall-10 { padding: 10px; }
.iq-pall-15 { padding: 15px; }
.iq-pall-20 { padding: 20px; }
.iq-pall-25 { padding: 25px; }
.iq-pall-30 { padding: 30px; }
.iq-pall-40 { padding: 40px; }
.iq-pall-50 { padding: 50px; }
.iq-pall-60 { padding: 60px; }
.iq-pall-70 { padding: 70px; }
.iq-pall-80 { padding: 80px; }
.iq-pall-90 { padding: 90px; }
.iq-pall-100 { padding: 100px; }

/*----------------
Padding Top and Bottom 
----------------*/
.iq-ptb-0 { padding: 0; }
.iq-ptb-10 { padding: 10px 0; }
.iq-ptb-15 { padding: 15px 0; }
.iq-ptb-20 { padding: 20px 0; }
.iq-ptb-25 { padding: 25px 0; }
.iq-ptb-30 { padding: 30px 0; }
.iq-ptb-40 { padding: 40px 0; }
.iq-ptb-50 { padding: 50px 0; }
.iq-ptb-60 { padding: 60px 0; }
.iq-ptb-70 { padding: 70px 0; }
.iq-ptb-80 { padding: 80px 0; }
.iq-ptb-90 { padding: 90px 0; }
.iq-ptb-100 { padding: 100px 0; }

/*----------------
Padding Left and right 
----------------*/
.iq-plr-0 { padding: 0; }
.iq-plr-10 { padding: 0 10px; }
.iq-plr-15 { padding: 0 15px; }
.iq-plr-20 { padding: 0 20px; }
.iq-plr-25 { padding: 0 25px; }
.iq-plr-30 { padding: 0 30px; }
.iq-plr-40 { padding: 0 40px; }
.iq-plr-50 { padding: 0 50px; }
.iq-plr-60 { padding: 0 60px; }
.iq-plr-70 { padding: 0 70px; }
.iq-plr-80 { padding: 0 80px; }
.iq-plr-90 { padding: 0 90px; }
.iq-plr-100 { padding: 0 100px; }

/*----------------
Padding top 
----------------*/
.iq-pt-0 { padding-top: 0px; }
.iq-pt-10 { padding-top: 10px; }
.iq-pt-15 { padding-top: 15px; }
.iq-pt-20 { padding-top: 20px; }
.iq-pt-25 { padding-top: 25px; }
.iq-pt-30 { padding-top: 30px; }
.iq-pt-40 { padding-top: 40px; }
.iq-pt-50 { padding-top: 50px; }
.iq-pt-60 { padding-top: 60px; }
.iq-pt-70 { padding-top: 70px; }
.iq-pt-80 { padding-top: 80px; }
.iq-pt-90 { padding-top: 90px; }
.iq-pt-100 { padding-top: 100px; }

/*----------------
Padding Bottom 
----------------*/
.iq-pb-0 { padding-bottom: 0px; }
.iq-pb-10 { padding-bottom: 10px; }
.iq-pb-15 { padding-bottom: 15px; }
.iq-pb-20 { padding-bottom: 20px; }
.iq-pb-25 { padding-bottom: 25px; }
.iq-pb-30 { padding-bottom: 30px; }
.iq-pb-40 { padding-bottom: 40px; }
.iq-pb-50 { padding-bottom: 50px; }
.iq-pb-60 { padding-bottom: 60px; }
.iq-pb-70 { padding-bottom: 70px; }
.iq-pb-80 { padding-bottom: 80px; }
.iq-pb-90 { padding-bottom: 90px; }
.iq-pb-100 { padding-bottom: 100px; }

/*----------------
Padding Left 
----------------*/
.iq-pl-0 { padding-left: 0; }
.iq-pl-10 { padding-left: 10px; }
.iq-pl-15 { padding-left: 15px; }
.iq-pl-20 { padding-left: 20px; }
.iq-pl-25 { padding-left: 25px; }
.iq-pl-30 { padding-left: 30px; }
.iq-pl-40 { padding-left: 40px; }
.iq-pl-50 { padding-left: 50px; }
.iq-pl-60 { padding-left: 60px; }
.iq-pl-70 { padding-left: 70px; }
.iq-pl-80 { padding-left: 80px; }
.iq-pl-90 { padding-left: 90px; }
.iq-pl-100 { padding-left: 100px; }

/*----------------
Padding Right 
----------------*/
.iq-pr-0 { padding-right: 0px; }
.iq-pr-10 { padding-right: 10px; }
.iq-pr-15 { padding-right: 15px; }
.iq-pr-20 { padding-right: 20px; }
.iq-pr-25 { padding-right: 25px; }
.iq-pr-30 { padding-right: 30px; }
.iq-pr-40 { padding-right: 40px; }
.iq-pr-50 { padding-right: 50px; }
.iq-pr-60 { padding-right: 60px; }
.iq-pr-70 { padding-right: 70px; }
.iq-pr-80 { padding-right: 80px; }
.iq-pr-90 { padding-right: 90px; }
.iq-pr-100 { padding-right: 100px; }

/*----------------
Margin All 
----------------*/
.iq-mall-0 { margin: 0; }
.iq-mall-10 { margin: 10px; }
.iq-mall-15 { margin: 15px; }
.iq-mall-20 { margin: 20px; }
.iq-mall-25 { margin: 25px; }
.iq-mall-30 { margin: 30px; }
.iq-mall-40 { margin: 40px; }
.iq-mall-50 { margin: 50px; }
.iq-mall-60 { margin: 60px; }
.iq-mall-70 { margin: 70px; }
.iq-mall-80 { margin: 80px; }
.iq-mall-90 { margin: 90px; }
.iq-mall-100 { margin: 100px; }

/*----------------
Margin Top and Bottom 
----------------*/
.iq-mtb-0 { margin: 0; }
.iq-mtb-10 { margin: 10px 0; }
.iq-mtb-15 { margin: 15px 0; }
.iq-mtb-20 { margin: 20px 0; }
.iq-mtb-25 { margin: 25px 0; }
.iq-mtb-30 { margin: 30px 0; }
.iq-mtb-40 { margin: 40px 0; }
.iq-mtb-50 { margin: 50px 0; }
.iq-mtb-60 { margin: 60px 0; }
.iq-mtb-70 { margin: 70px 0; }
.iq-mtb-80 { margin: 80px 0; }
.iq-mtb-90 { margin: 90px 0; }
.iq-mtb-100 { margin: 100px 0; }

/*----------------
Margin Left and Right 
----------------*/
.iq-mlr-0 { margin: 0 !important; }
.iq-mlr-10 { margin: 0 10px; }
.iq-mlr-15 { margin: 0 15px; }
.iq-mlr-20 { margin: 0 20px; }
.iq-mlr-25 { margin: 0 25px; }
.iq-mlr-30 { margin: 0 30px; }
.iq-mlr-40 { margin: 0 40px; }
.iq-mlr-50 { margin: 0 50px; }
.iq-mlr-60 { margin: 0 60px; }
.iq-mlr-70 { margin: 0 60px; }
.iq-mlr-80 { margin: 0 80px; }
.iq-mlr-90 { margin: 0 80px; }
.iq-mlr-100 { margin: 0 100px; }

/*----------------
Margin Top 
----------------*/
.iq-mt-0 { margin-top: 0px; }
.iq-mt-5 { margin-top: 5px; }
.iq-mt-10 { margin-top: 10px; }
.iq-mt-15 { margin-top: 15px; }
.iq-mt-20 { margin-top: 20px; }
.iq-mt-25 { margin-top: 25px; }
.iq-mt-30 { margin-top: 30px; }
.iq-mt-40 { margin-top: 40px; }
.iq-mt-50 { margin-top: 50px; }
.iq-mt-60 { margin-top: 60px; }
.iq-mt-70 { margin-top: 70px; }
.iq-mt-80 { margin-top: 80px; }
.iq-mt-90 { margin-top: 90px; }
.iq-mt-100 { margin-top: 100px; }

/*----------------
Margin Bottom 
----------------*/
.iq-mb-0 { margin-bottom: 0px; }
.iq-mb-10 { margin-bottom: 10px; }
.iq-mb-15 { margin-bottom: 15px; }
.iq-mb-20 { margin-bottom: 20px; }
.iq-mb-25 { margin-bottom: 25px; }
.iq-mb-30 { margin-bottom: 30px; }
.iq-mb-40 { margin-bottom: 40px; }
.iq-mb-50 { margin-bottom: 50px; }
.iq-mb-60 { margin-bottom: 60px; }
.iq-mb-70 { margin-bottom: 70px; }
.iq-mb-80 { margin-bottom: 80px; }
.iq-mb-90 { margin-bottom: 90px; }
.iq-mb-100 { margin-bottom: 100px; }

/*----------------
Margin Left 
----------------*/
.iq-ml-0 { margin-left: 0px; }
.iq-ml-10 { margin-left: 10px; }
.iq-ml-15 { margin-left: 15px; }
.iq-ml-20 { margin-left: 20px; }
.iq-ml-25 { margin-left: 25px; }
.iq-ml-30 { margin-left: 30px; }
.iq-ml-40 { margin-left: 40px; }
.iq-ml-50 { margin-left: 50px; }
.iq-ml-60 { margin-left: 60px; }
.iq-ml-70 { margin-left: 70px; }
.iq-ml-80 { margin-left: 80px; }
.iq-ml-90 { margin-left: 90px; }
.iq-ml-100 { margin-left: 100px; }

/*----------------
Margin Right
----------------*/
.iq-mr-0 { margin-right: 0px; }
.iq-mr-10 { margin-right: 10px; }
.iq-mr-15 { margin-right: 15px; }
.iq-mr-20 { margin-right: 20px; }
.iq-mr-25 { margin-right: 25px; }
.iq-mr-30 { margin-right: 30px; }
.iq-mr-40 { margin-right: 40px; }
.iq-mr-50 { margin-right: 50px; }
.iq-mr-60 { margin-right: 60px; }
.iq-mr-70 { margin-right: 70px; }
.iq-mr-80 { margin-right: 80px; }
.iq-mr-90 { margin-right: 90px; }
.iq-mr-100 { margin-right: 100px; }

/*---------------------------------------------------------------------
                            Text Weight 
-----------------------------------------------------------------------*/
.iq-tw-1 { font-weight: 100; }
.iq-tw-2 { font-weight: 200; }
.iq-tw-3 { font-weight: 300; }
.iq-tw-4 { font-weight: 400; }
.iq-tw-5 { font-weight: 500; }
.iq-tw-6 { font-weight: 600; }
.iq-tw-7 { font-weight: 700; }
.iq-tw-8 { font-weight: 800; }
.iq-tw-9 { font-weight: 900; }

/*---------------------------------------------------------------------
                            Text Color 
-----------------------------------------------------------------------*/
.iq-font-blue { color: #16181c; }
.iq-font-white { color: #ffffff; }
.iq-font-black { color: #23292c; }
.iq-font-light { color: #666666; }
.iq-font-yellow { color: #f9b707; }

/*---------------------------------------------------------------------
                            Font Size 
-----------------------------------------------------------------------*/
.iq-font-15 { font-size: 15px; }
.iq-font-30 { font-size: 30px; }

/*---------------------------------------------------------------------
                          Background Color 
-----------------------------------------------------------------------*/
.white-bg { background: #ffffff; }
.grey-bg { background: #f7f7f7; }
.blue-bg { background: #16181c; }
.light-bg { /*background: #0c2801;*/ 
   background-image: url("../images/Technology-Background.jpg"); 
}
.yellow-bg { background: #f9b707; }

/*---------------------------------------------------------------------
                             BG Effect
-----------------------------------------------------------------------*/
.iq-parallax { position: relative; background-size: cover !important; -webkit-background-size: cover !important; -moz-background-size: cover !important; -ms-background-size: cover !important; background-origin: initial; background-position: center center !important; background-repeat: no-repeat; }

/*---------------------------------------------------------------------
                       Background Gradient 
---------------------------------------------------------------------*/
.iq-bg-over { position: relative; }

/* Background Gradient blue */
.iq-over-blue-10:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(5, 7, 28, 0.1); }
.iq-over-blue-20:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(5, 7, 28, 0.2); }
.iq-over-blue-30:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(5, 7, 28, 0.3); }
.iq-over-blue-40:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(5, 7, 28, 0.4); }
.iq-over-blue-50:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(5, 7, 28, 0.5); }
.iq-over-blue-60:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(5, 7, 28, 0.6); }
.iq-over-blue-70:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(5, 7, 28, 0.7); }
.iq-over-blue-80:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(5, 7, 28, 0.8); }
.iq-over-blue-85:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(5, 7, 28, 0.85); }
.iq-over-blue-90:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(5, 7, 28, 0.9); }
.iq-over-blue-95:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(5, 7, 28, 0.95); }

/* Background Gradient Black */
.iq-over-black-10:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(0, 0, 0, 0.1); }
.iq-over-black-20:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(0, 0, 0, 0.2); }
.iq-over-black-30:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(0, 0, 0, 0.3); }
.iq-over-black-40:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(0, 0, 0, 0.4); }
.iq-over-black-50:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(0, 0, 0, 0.5); }
.iq-over-black-60:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(0, 0, 0, 0.6); }
.iq-over-black-70:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(0, 0, 0, 0.7); }
.iq-over-black-80:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(0, 0, 0, 0.8); }
.iq-over-black-85:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(0, 0, 0, 0.85); }
.iq-over-black-90:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(0, 0, 0, 0.9); }
.iq-over-black-95:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(0, 0, 0, 0.95); }

/* Background Gradient White */
.iq-over-white-10:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(255, 255, 255, 0.1); }
.iq-over-white-20:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(255, 255, 255, 0.2); }
.iq-over-white-30:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(255, 255, 255, 0.3); }
.iq-over-white-40:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(255, 255, 255, 0.4); }
.iq-over-white-50:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(255, 255, 255, 0.5); }
.iq-over-white-60:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(255, 255, 255, 0.6); }
.iq-over-white-70:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(255, 255, 255, 0.7); }
.iq-over-white-80:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(255, 255, 255, 0.8); }
.iq-over-white-85:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(255, 255, 255, 0.85); }
.iq-over-white-90:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(255, 255, 255, 0.9); }
.iq-over-white-95:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(255, 255, 255, 0.95); }
[class*='iq-over-']:before { z-index: 0; }

/*---------------------------------------------------------------------
                           Section Title
-----------------------------------------------------------------------*/
.heading-title { margin-bottom: 11px; text-align: center; padding-top: 73px;}
.heading-title small { font-weight: 400; font-size: 16px; text-transform: uppercase; font-family: 'Montserrat', sans-serif; letter-spacing: 4px; }
.heading-title.left { text-align: left; margin-bottom: 20px; }
.heading-title .title { position: relative; padding-bottom: 15px; display: inline-block; width: 100%; }
.heading-title p { padding: 0 100px; display: block; }
.heading-title .title.white:before { background: #ffffff; }
.heading-titlees { margin-bottom: 11px; padding-top: 73px;}

/*Heading Left*/
.heading-left .title { position: relative; padding: 0 0 8px 0; margin: 0 0 20px 0; }
.heading-left .title:before { position: absolute; content: ""; left: 0; width: 50px; height: 3px; background: #f9b707; bottom: 0; }
.small-title { position: relative; padding-bottom: 10px; margin-bottom: 15px }
.small-title:before { content: ""; position: absolute; bottom: 0; left: 0; width: 40px; height: 4px; background: #f560b9; display: inline-block; }
#canvas1 { position: fixed; top: 0; left: 0; width: 100%; height: 100vh; z-index: -1; }

.hr-text2{
	font-weight: bold;
}
.title-cn{
	font-size: 48px;
}
.info-1{
	  display: block;
  	  margin-left: auto;
      margin-right: auto;
}
.title-con{
	font-size: 42px;
}
/*---------------------------------------------------------------------
                              Header
---------------------------------------------------------------------*/
header { padding: 20px 80px; position: absolute; display: inline-block; top: 0; left: 0; width: 100%; -webkit-transition: all 250ms ease-in-out 0s; -moz-transition: all 250ms ease-in-out 0s; -ms-transition: all 250ms ease-in-out 0s; -o-transition: all 250ms ease-in-out 0s; transition: all 250ms ease-in-out 0s; z-index: 9999; }
header .navbar { padding: 0; }
/*header .navbar-brand img { height: 40px; }*/
header .navbar-light .nav-item { margin: 0 10px; }
header .navbar-light .nav-item .nav-link { color: #fff; font-size: 16px; line-height: normal; position: relative; -webkit-transition: all 250ms ease-in-out 0s; -moz-transition: all 250ms ease-in-out 0s; -ms-transition: all 250ms ease-in-out 0s; -o-transition: all 250ms ease-in-out 0s; transition: all 250ms ease-in-out 0s; }
header .navbar-light .navbar-nav .nav-link:before { content: ""; display: inline-block; position: absolute; bottom: -3px; left: 50%; margin-left: -3px; background: #f560b9; height: 6px; width: 6px; border-radius: 90px; opacity: 0; -webkit-transition: all 250ms ease-in-out 0s; -moz-transition: all 250ms ease-in-out 0s; -ms-transition: all 250ms ease-in-out 0s; -o-transition: all 250ms ease-in-out 0s; transition: all 250ms ease-in-out 0s; }
header .navbar-light .navbar-nav .nav-link:hover, header .navbar-light .navbar-nav .nav-link:focus, header .navbar-light .navbar-nav .nav-item .nav-link.active { color: #f560b9; }
header .navbar-light .navbar-nav .nav-link:hover:before, .navbar-light .navbar-nav .nav-link:focus:before, .navbar-light .navbar-nav .nav-item .nav-link.active:before { opacity: 1; }
header .navbar-light .nav { position: relative; }
header .navbar-light .nav:before { content: ""; position: absolute; top: 5px; left: 5px; height: 25px; width: 1px; display: inline-block; background: #c7c7c7; }
header .navbar-light .nav .button:hover, header .navbar-light .nav .button:focus { color: #131519; }
header.menu-sticky { position: fixed; background: #000000; 
  padding: 6px 30px; box-shadow: 0px 0px 30px 0px rgba(255, 255, 255, 0.1); }
.dropdown-item.active, .dropdown-item:active { background: #f9b707; }

.myAccountBox{
  color: #fff;
  text-align: right;
  padding-right: 24px;
}

/*Login*/
.iq-login .modal-header { padding: 20px 30px; }
.iq-login .modal-body { padding: 30px; }
.iq-login .modal-footer { padding: 20px 30px; display: inline; }
.iq-login .form-control { border: 1px solid #ced4da; border-radius: 0; height: 45px !important; box-shadow: none; }
.iq-login .modal-body .button { width: 100%; text-align: center; }
.iq-login .modal-dialog { max-width: 400px; }
.iq-login .modal-footer .iq-media-blog { margin: 0; padding: 0; }
.iq-login .modal-footer .iq-media-blog li { display: inline-block; list-style: none; padding: 0; margin: 0px 4px; }
.iq-login .modal-footer .iq-media-blog li a { display: block; width: 40px; height: 40px; line-height: 40px; font-size: 16px; color: #f9b707; background: transparent; border: 1px solid #f9b707; text-align: center; border-radius: 50%; }
.iq-login .modal-footer .iq-media-blog li a:hover { background: #f9b707; border-color: #f9b707; color: #fff; }
.iq-login .modal-header .close { outline: none; }

/*---------------------------------------------------------------------
                              Banner
---------------------------------------------------------------------*/
.iq-banner { position: relative; padding: 15% 0 6%; }
.iq-banner:before { position: absolute; top: 0%; left: 0%; content: ""; width: 100%; z-index: 999; height: 100%; display: inline-block; 
 /*  background-image: url('../images/01.png'); */ }
.banner-info { position: relative; z-index: 999; }
.iq-banner .iq-countdown { background: #131519; border-radius: 30px; padding: 40px 40px; position: relative; }
.iq-banner .wave-poistion { position: absolute; top: 0; left: 0; z-index: 99; }
.iq-banner .wave-poistion canvas { width: 100% !important; height: 100% !important; }
.iq-countdown #countdown { list-style: none; margin: 40px 0; padding: 0; display: inline-block; width: 100%; }
.iq-countdown #countdown li { display: inline-block; padding: 0 20px; position: relative; }
.iq-countdown #countdown li:before { content: ""; position: absolute; top: 0; right: 0; width: 1px; height: 100%; display: inline-block; background: rgba(255, 255, 255, 0.1); }
.iq-countdown #countdown li:first-child { padding-left: 0; }
.iq-countdown #countdown li:last-child { padding-right: 0; }
.iq-countdown #countdown li:last-child:before { display: none; }
.iq-countdown #countdown li span { font-size: 44px; line-height: normal; font-weight: 700; color: #fff; }
.iq-countdown #countdown li p { font-size: 14px; font-weight: 700; color: #fff; margin-bottom: 0; }
.iq-banner  .iq-countdown .list-inline { margin-top: 15px; }
.iq-banner .iq-countdown .list-inline li a { color: #fff; font-size: 18px; margin: 0 5px; }
.iq-banner .iq-countdown .list-inline li a:hover { color: #f9b707; }
.iq-progress-bar-text { margin-bottom: 10px; }
.iq-progress-bar-text span { float: right }
.iq-progress-bar { background: #fff none repeat scroll 0 0; box-shadow: 0 0 0; height: 15px; margin: 0 0 30px; position: relative; width: 100%; border-radius: 90px; }
.iq-progress-bar > span { background: #f9b707 none repeat scroll 0 0; display: block; height: 100%; width: 0; border-radius: 90px; }

.center{
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 480px) {
            
	.iq-font-white{
		text-align: center;
	}
	.banner-text{
		margin-top: 26px;
	}
        }
@media (min-width: 481px) and (max-width: 767px) {
           
				.iq-font-white{
					text-align: center;
				}
				.banner-text{
					margin-top: 26px;
				}
        	}
@media (min-width: 768px) and (max-width: 1024px){
            
			.iq-font-white{
					text-align: center;
				}
				.banner-text{
					margin-top: 26px;
				}
        }

/*---------------------------------------------------------------------
                              Banner 2
---------------------------------------------------------------------*/
.iq-banner-2 { position: relative; padding: 20% 0 8%; }
.iq-banner-2:before { position: absolute; top: 0%; left: 0%; content: ""; width: 100%;  height: 100%; display: inline-block; 
  background-image: url('../images/02.jpg');
 }
.iq-banner-2 .iq-countdown { border-radius: 30px; padding: 40px 40px; position: relative; }
.iq-banner-2  .iq-countdown .list-inline { margin-top: 15px; }
.iq-banner-2 .iq-countdown .list-inline li a { color: #fff; font-size: 18px; margin: 0 5px; }
.iq-banner-2 .iq-countdown .list-inline li a:hover { color: #f9b707; }

/* Canvas Banner */
.iq-canvas-banner { padding: 15% 0 10%; }
.iq-canvas2 { position: absolute; bottom: 0; left: 0; width: 100%; height: 100%; display: inline-block; }
#world { position: absolute; top: 0; left: 0; width: 100%; height: 100%; display: inline-block; }

/* ---- particles.js container ---- */
#particles-js { width: 100%; height: 100%; 
  background-image:url("./futuristic-technology-BG.jpg");
   background-size: cover; background-position: 50% 50%; background-repeat: no-repeat;  position: absolute; top: 0; left: 0; }

.text-coin{
	font-size: 15px;
	font-weight: bold;
	letter-spacing: 3px;
}
.smartcard{
	display: block;
	margin-left: auto;
}
@media (max-width: 480px) {
            
	.smartcard{
		margin-right: 36%;
	}
	.banner-text{
		margin-top: 26px;
	}
        }
@media (min-width: 481px) and (max-width: 767px) {
           
				.iq-font-white{
					text-align: center;
				}
				.banner-text{
					margin-top: 26px;
				}
        	}
@media (min-width: 768px) and (max-width: 1024px){
            
			.iq-font-white{
					text-align: center;
				}
				.banner-text{
					margin-top: 26px;
				}
        }

/*---------------------------------------------------------------------
                              Banner 3
---------------------------------------------------------------------*/
.iq-banner-3 { position: relative; padding: 15% 0 10%; overflow: hidden; }
.iq-banner-3:before { position: absolute; top: 0%; left: 0%; content: ""; width: 100%;  height: 100%; display: inline-block; 
  background-image: url("../images/futuristic-technology-BG.jpg");
 }
.iq-banner-3 .ico-img { margin-top: 10px; bottom: -45px; right: -60px; width: 95%; }

.crypto-coin .hr-text{
	font-weight: bold;
}
.divider{
	border: 3px solid;
	width: 120px;
	margin-top: 8px;
}
.crypto-coin-text{
	margin-top: 30px;
}
.crypto-coin-text{
	text-align: left;
}

/*---------------------------------------------------------------------
                              Clients
---------------------------------------------------------------------*/
.iq-our-clients { position: relative; z-index: 99; }

/*---------------------------------------------------------------------
                             Fancy-box 
---------------------------------------------------------------------*/
.iq-fancy-box { background: #181a21; padding: 0 20px 20px; margin-bottom: 120px; position: relative; }
.iq-fancy-box .fancy-icon img { width: 60%; margin-top: -80px; }
.iq-fancy-box .fancy-content h5 { font-family: 'Comfortaa', cursive; margin: 25px 0 25px; }

/* Fancy box 2 */
.fancy-box { text-align: center; border: 1px solid #f9b707; border-radius: 10px; }
.fancy-box .heading { padding: 20px; background: #f9b707; border-radius: 10px 10px 0 0; }
.fancy-box h5 { margin: 20px 0; }
.fancy-box h6 { margin: 10px 0; }

/*---------------------------------------------------------------------
                             Tranding Platform
-----------------------------------------------------------------------*/
.iq-tranding-Platform .nav-tabs { border: none; }
.iq-tranding-Platform .nav-tabs .nav-link:first-child { -webkit-border-top-left-radius: 30px; -moz-border-radius-topleft: 30px; border-top-left-radius: 30px; }
.iq-tranding-Platform .nav-tabs .nav-link:last-child { -webkit-border-top-right-radius: 30px; -moz-border-radius-topright: 30px; border-top-right-radius: 30px; }
.iq-tranding-Platform .nav-tabs .nav-link { padding: 15px 20px; text-align: center; width: 20%; color: #fff; border: none; background: #101114; position: relative; }
.iq-tranding-Platform .nav-tabs .nav-link:hover:before, .iq-tranding-Platform .nav-tabs .nav-link.active:before, .iq-tranding-Platform .nav-tabs .nav-item.show .nav-link { content: ""; bottom: 0; left: 0; display: inline-block; position: absolute; height: 2px; width: 100%; background: #f9b707; }
.iq-tranding-Platform .nav-tabs .nav-link i { font-size: 42px; margin-bottom: 5px; }
.iq-tranding-Platform .nav-tabs .nav-link span { display: block; font-size: 16px; }
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus { background: #131519; border: none; color: #f9b707; }
.iq-tranding-Platform .light-bg { -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; }
.iq-tranding-Platform .tab-content { padding: 15px; }
.table-bordered td, .table-bordered th { border: 1px solid #292c34; }
#chartdiv { width: 100%; height: 500px; }
#chartdiv .amcharts-chart-div a, #chartrounddiv .amcharts-chart-div a, .amcharts-export-menu.amcharts-export-menu-top-right.amExportButton { display: none !important; }
#chartrounddiv { width: 100%; height: 500px; font-size: 11px; }

#chartnew { width: 100%; height: 500px; }

.amcharts-pie-slice { transform: scale(1); transform-origin: 50% 50%; transition-duration: 0.3s; transition: all .3s ease-out; -webkit-transition: all .3s ease-out; -moz-transition: all .3s ease-out; -o-transition: all .3s ease-out; cursor: pointer; box-shadow: 0 0 30px 0 #fff; }
.amcharts-pie-slice:hover { transform: scale(1.1); filter: url(#shadow); }

/*************************************
Listing Check
**************************************/
.trending-2 ul.listing-mark { padding: 0; margin: 0; }
.trending-2 ul.listing-mark li { position: relative; list-style-type: none; padding-left: 25px; margin: 10px 0; }
.trending-2 ul.listing-mark li:after { content: "\f00c"; }

/*---------------------------------------------------------------------
List Style
----------------------------------------------------------------------*/
.trending-2 ul { padding: 0; margin: 0; padding-left: 20px; }
.trending-2 ul li:after { position: absolute; color: #4cbb20; content: ""; font-family: 'FontAwesome'; left: 0; top: 0; }
.trending-2 ul li.dark:after { color: #222222; }

/***************************
Token Sale
****************************/
.iq-token { position: relative; }
.iq-token .iq-icon { float: left; border-radius: 90px; height: 70px; width: 70px; line-height: 70px; text-align: center; margin-right: 15px; }
.iq-token .iq-icon i { font-size: 36px; color: #fff; }
.iq-token .iq-icon span { font-size: 30px; }
.iq-token .fancy-content { display: table-cell; }
.iq-token .fancy-content p { padding: 0; margin: 0; }
.iq-token.iq-bg .step { position: absolute; right: 0; top: 25px; font-size: 80px; color: #f7f7f7; z-index: -1; font-family: 'PT Sans', sans-serif; font-weight: 600; }
.iq-token.iq-bg { padding: 20px 15px; position: relative; }
.iq-token.iq-bg:before { position: absolute; content: ""; right: 0; top: 0; width: 0; height: 0; background: #f7f7f7; transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; z-index: -1; border-radius: 5px; }

/*---------------------------------------------------------------------
                            Token 
-----------------------------------------------------------------------*/
.iq-countdown-2 h2 { font-size: 60px; font-weight: 600; line-height: 80px; }
.iq-countdown-2 { 
    box-shadow: 10px 10px 142px -39px rgba(0, 0, 0, 0.75); 
  }
.iq-countdown-2 h5 { font-size: 21px; line-height: 24px; }
.iq-countdown-2 #countdown { list-style: none; margin: 40px 0 20px 0; padding: 0; display: inline-block; width: 100%; }
.iq-countdown-2 #countdown li { display: inline-block; }
.iq-countdown-2 #countdown li span { font-size: 50px; line-height: 80px; font-weight: 600; }
.iq-countdown-2 #countdown li.seperator { font-size: 50px; line-height: 40px; vertical-align: top; margin-top: 15px; }
.iq-countdown-2 #countdown li p { font-size: 20px; }
.iq-countdown-2 input { width: 100%; border: none; box-shadow: none !important; background: rgba(255, 255, 255, 0.2) !important; height: 44px; border-radius: 3px 0 0 3px; min-width: 300px; }
.iq-countdown-2 .iq-subscribe { text-align: center; display: inline-block; }
.iq-countdown-2 input { color: #ffffff !important; }
.iq-countdown-2 input::-webkit-input-placeholder { color: #ffffff !important; }
.iq-countdown-2 input::-moz-placeholder { color: #ffffff !important; }
.iq-countdown-2 input:-moz-placeholder { color: #ffffff !important; }
.iq-countdown-2 input:-ms-input-placeholder { color: #ffffff !important; }
.iq-countdown-2 input:focus { outline: none; }
.iq-countdown-2 .button { border-radius: 0 3px 3px 0; }
.iq-countdown-2 { background: #242f6c; padding-bottom: 40px; border-radius: 10px; }
.iq-countdown-2 i { font-size: 20px; padding-left: 10px; }

/***************************
Token Sale
****************************/
.iq-token { position: relative; }
.iq-token .iq-icon { float: left; border-radius: 90px; height: 70px; width: 70px; line-height: 70px; text-align: center; margin-right: 15px; }
.iq-token .iq-icon i { font-size: 36px; color: #fff; }
.iq-token .iq-icon span { font-size: 30px; }
.iq-token .fancy-content { display: table-cell; }
.iq-token .fancy-content p { padding: 0; margin: 0; }
.iq-token.iq-bg .step { position: absolute; right: 0; top: 25px; font-size: 80px; color: #f7f7f7; z-index: -1; font-family: 'PT Sans', sans-serif; font-weight: 600; }
.iq-token.iq-bg { padding: 20px 15px; position: relative; }
.iq-token.iq-bg:before { position: absolute; content: ""; right: 0; top: 0; width: 0; height: 0; background: #f7f7f7; transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; z-index: -1; border-radius: 5px; }

/*---------------------------------------------------------------------
                            Token 
-----------------------------------------------------------------------*/
.iq-countdown-2 h2 { font-size: 60px; font-weight: 600; line-height: 80px; }
.iq-countdown-2 { 
    box-shadow: 10px 10px 142px -39px rgba(0, 0, 0, 0.75); }
.iq-countdown-2 h5 { font-size: 21px; line-height: 24px; }
.iq-countdown-2 #countdown { list-style: none; margin: 40px 0 20px 0; padding: 0; display: inline-block; width: 100%; }
.iq-countdown-2 #countdown li { display: inline-block; }
.iq-countdown-2 #countdown li span { font-size: 50px; line-height: 80px; font-weight: 600; }
.iq-countdown-2 #countdown li.seperator { font-size: 50px; line-height: 40px; vertical-align: top; margin-top: 15px; }
.iq-countdown-2 #countdown li p { font-size: 20px; }
.iq-countdown-2 input { width: 100%; border: none; box-shadow: none !important; background: rgba(255, 255, 255, 0.2) !important; height: 44px; border-radius: 3px 0 0 3px; min-width: 300px; }
.iq-countdown-2 .iq-subscribe { text-align: center; display: inline-block; }
.iq-countdown-2 input { color: #ffffff !important; }
.iq-countdown-2 input::-webkit-input-placeholder { color: #ffffff !important; }
.iq-countdown-2 input::-moz-placeholder { color: #ffffff !important; }
.iq-countdown-2 input:-moz-placeholder { color: #ffffff !important; }
.iq-countdown-2 input:-ms-input-placeholder { color: #ffffff !important; }
.iq-countdown-2 input:focus { outline: none; }
.iq-countdown-2 .button { border-radius: 0 3px 3px 0; }
.iq-countdown-2 { background: #242f6c; padding-bottom: 40px; border-radius: 10px; }
.iq-countdown-2 i { font-size: 20px; padding-left: 10px; }

/*---------------------------------------------------------------------
                                 Team
-----------------------------------------------------------------------*/
.our-team { text-align: center; }
.our-team .team-image { width: 80%; height: 80%; border-radius: 50%; border: 5px solid #d7d7d7; position: relative; margin: 0 auto; transition: all 0.5s ease 0s; }
.our-team:hover .team-image { border-color: #f9b707; }
.our-team .team-image img { width: 100%; height: auto; border-radius: 50%; }
.our-team .social { margin: 0; padding: 0; list-style: none; width: 100%; position: absolute; top: 45%; opacity: 0; transform: scale(0); transition: all 0.5s ease 0s; }
.our-team:hover .social { opacity: 1; transform: scale(1); animation: 0.5s ease 0s normal 1 bounce; }
.our-team .social li { display: inline-block; border-radius: 50%; }
.our-team .social li:first-child { margin-left: -30px; float: left; }
.our-team .social li:last-child { margin-right: -30px; float: right; }
.our-team .social li a { display: block; width: 50px; height: 50px; line-height: 46px; border-radius: 50%; background-color: #fff; font-size: 18px; color: #f9b707; border: 3px solid #f9b707; }
.our-team .team-content { padding: 40px 0 0; }
.our-team .team-content .title { font-family: 'Comfortaa', cursive; }
@keyframes bounce {
  0% { opacity: 0; -webkit-transform: scale(.3); transform: scale(.3) }
  50% { opacity: 1; -webkit-transform: scale(1.05); transform: scale(1.05) }
  70% { -webkit-transform: scale(.9); transform: scale(.9) }
  100% { opacity: 1; -webkit-transform: scale(1); transform: scale(1) }
}
@media screen and (max-width:990px) {
  .our-team { margin-bottom: 20px; }
}


@media (max-width: 480px) {
            .buttons {
                margin-left: 25%;
            }
        }
@media (min-width: 481px) and (max-width: 767px) {
            .buttons {
                margin-left: 36%;
            }
        }
@media (min-width: 768px) and (max-width: 1024px){
            .buttons {
                margin-left: 35%;
            }
        }

/*---------------------------------------------------------------------
                                 Team 2
-----------------------------------------------------------------------*/
.our-team-1 { text-align: center; }
.our-team-1 .team-image { width: 80%; height: 80%; border-radius: 50%; border: 5px solid #d7d7d7; position: relative; margin: 0 auto; transition: all 0.5s ease 0s; }
.our-team-1:hover .team-image { border-color: #f9b707; }
.our-team-1 .team-image img { width: 100%; height: auto; border-radius: 50%; }
.our-team-1 .social { margin: 0; padding: 0; list-style: none; width: 100%; position: absolute; top: 45%; opacity: 0; transform: scale(0); transition: all 0.5s ease 0s; }
.our-team-1:hover .social { opacity: 1; transform: scale(1); animation: 0.5s ease 0s normal 1 bounce; }
.our-team-1 .social li { display: inline-block; border-radius: 50%; }
.our-team-1 .social li:first-child { margin-left: -30px; float: left; }
.our-team-1 .social li:last-child { margin-right: -30px; float: right; }
.our-team-1 .social li a { display: block; width: 50px; height: 50px; line-height: 46px; border-radius: 50%; background-color: #fff; font-size: 18px; color: #f9b707; border: 3px solid #f9b707; }
.our-team-1 .team-content { padding: 40px 0 0; }
.our-team-1 .team-content .title { font-family: 'Comfortaa', cursive; }
@keyframes bounce {
  0% { opacity: 0; -webkit-transform: scale(.3); transform: scale(.3) }
  50% { opacity: 1; -webkit-transform: scale(1.05); transform: scale(1.05) }
  70% { -webkit-transform: scale(.9); transform: scale(.9) }
  100% { opacity: 1; -webkit-transform: scale(1); transform: scale(1) }
}
@media screen and (max-width:990px) {
  .our-team-1 { margin-bottom: 20px; }
}

/*************************************
Teams 3 
**************************************/
.iq-our-team-2 .team-blog { text-align: center; overflow: hidden; border-radius: 130px; width: 80%; height: 80%; border-radius: 50%; border: 2px solid #4bbb1e; position: relative; margin: 0 auto; transition: all 0.5s ease 0s; }
.iq-our-team-2 .team-blog img { vertical-align: bottom; border-radius: 130px; }
.iq-our-team-2 .team-blog:before { position: absolute; content: ""; width: 100%; height: 100%; left: 0; top: 0; background: rgba(75, 187, 30, 0.9); opacity: 0; }
.iq-our-team-2 .team-blog .team-description { position: absolute; width: 100%; top: -10%; left: 0; color: #fff; margin-top: -20px; padding: 0; opacity: 0; }
.iq-our-team-2 .team-blog .team-description a:hover { color: #222222; }
.iq-our-team-2 .team-blog:hover:before { opacity: 1; }
.iq-our-team-2 .team-blog:hover .team-description { top: 40%; opacity: 1; }
.iq-our-team-2 .team-blog:hover .team-social { bottom: 30px; opacity: 1; }
.iq-our-team-2 .team-blog .team-social { position: absolute; left: 0; bottom: -10%; width: 100%; opacity: 0; }
.iq-our-team-2 .team-blog .team-social ul { background: #ffffff; width: 100%; display: inline-block; }
.iq-our-team-2 .team-blog .team-social ul li { display: inline-block; }
.iq-our-team-2 .team-blog .team-social ul li a:hover { color: #222222; }
.iq-our-team-2 .team-blog .team-social ul li a { font-size: 16px; padding: 0 5px; color: #4bbb1e; display: inline-block; float: none; line-height: 45px; text-align: center; background: transparent; }
.iq-our-team-2, .iq-our-team-2 .team-blog:before, .iq-our-team-2 .team-blog:hover:before, .iq-our-team-2 .team-blog:hover .team-description, .iq-our-team-2 .team-blog:hover .team-social, .iq-our-team-2 .team-blog .team-social { -webkit-transition: all 0.3s ease-out 0s; -moz-transition: all 0.3s ease-out 0s; -ms-transition: all 0.3s ease-out 0s; -o-transition: all 0.3s ease-out 0s; transition: all 0.3s ease-out 0s; }

/*---------------------------------------------------------------------
                          Executive Partners
-----------------------------------------------------------------------*/
.Signal-partners { margin-bottom: 50px; }
.Signal-partners  img { width: 80%; }

/*---------------------------------------------------------------------
                              Blog
-----------------------------------------------------------------------*/
.iq-blog-box { -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06); -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06); box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06); border-radius: 0px 0px 15px 15px; -moz-border-radius: 0px 0px 15px 15px; -webkit-border-radius: 0px 0px 15px 15px; border: 0px solid #000000; }
.iq-blog-box .iq-blog-image { position: relative; }
.iq-blog-box .iq-blog-image img { width: 100%; }
.iq-blog-detail { padding: 20px 15px 15px; background: rgb(30, 33, 38, 0.9); border-radius: 0px 0px 10px 10px; -moz-border-radius: 0px 0px 10px 10px; -webkit-border-radius: 0px 0px 10px 10px; border: 0px solid #000000; }
.iq-blog-detail .blog-title a h5 { font-size: 22px; font-family: 'Comfortaa', cursive; transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; }
.iq-blog-detail a { color: #fff; }
.iq-blog-detail a:hover, .iq-blog-detail .blog-title a:hover h5 { color: #f9b707; }
.iq-blog-meta { margin: 15px 0 5px; border-top: 1px solid #292c34; padding-top: 15px; }
.iq-blog-meta ul li { margin: 0 15px 0 0; padding: 0; font-size: 16px; }
.iq-blog-meta ul li:last-child { margin: 0; }

/*video vimeo and youtube*/
.iq-bolg-video { height: 0; padding-bottom: 56.5%; position: relative; overflow: hidden; }
.iq-bolg-video video, .iq-bolg-video embed, .iq-bolg-video iframe, .iq-bolg-video object { top: 0; left: 0; width: 100%; height: 100%; position: absolute; border: none; }

/*blog-entry-audio*/
.media-wrapper video { width: 100%; height: 100%; display: inline-block; float: left; }
.players { position: absolute; bottom: 15px; display: inline-block; left: 50%; transform: translateX(-50%); -webkit-transform: translateX(-50%); -o-transform: translateX(-50%); -ms-transform: translateX(-50%); -moz-transform: translateX(-50%); }

/*blog-border-radias*/
.blog-style .iq-blog-box .iq-blog-image img, .blog-style .media-wrapper, .blog-style .media-wrapper video, .blog-style  .iq-bolg-video { border-radius: 10px 10px 0px 0px; }

/*---------------------------------------------------------------------
                             FAQ
-----------------------------------------------------------------------*/
.iq-accordion { z-index: 9; position: relative; }
.iq-accordion .ad-title { position: relative; font-family: 'Comfortaa', cursive; padding: 22px 25px; font-size: 16px; line-height: normal; cursor: pointer; background-color: rgba(255, 255, 255, 0.05); display: block; text-align: left; color: #fff; text-decoration: none; border-radius: 10px; }
.iq-accordion .ad-title:before { -webkit-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.06); -moz-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.06); box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.06); cursor: pointer; content: "\f107"; font-family: "FontAwesome"; position: absolute; top: 50%; right: 15px; margin-top: -17px; display: inline-block; text-align: center; color: #fff; font-size: 18px; line-height: 35px; height: 35px; width: 35px; font-weight: normal; -webkit-transition: all 0.25s ease-in-out 0s; -moz-transition: all 0.25s ease-in-out 0s; transition: all 0.25s ease-in-out 0s; background: #f9b707; border-radius: 90px; }
.iq-accordion .ad-active .ad-title:before { content: "\f106"; font-family: "FontAwesome"; }
.iq-accordion .ad-details { display: none; overflow: hidden; text-align: left; padding: 20px 25px 0; line-height: 26px; background: transparent; border: 1px solid transparent; border-top: none; }
.iq-accordion .iq-ad-block { margin-bottom: 20px; }
.iq-accordion .iq-ad-block:last-child { margin-bottom: 0; }
.anything-video { width: 100%; min-height: 300px; }

/*---------------------------------------------------------------------
                              Contact us
-----------------------------------------------------------------------*/
.contact-us .heading-title { margin-bottom: 0; }
.contact-us .heading-title .title { padding-bottom: 5px; }
.contact-us .map { display: inline-block; width: 100%; height: 100%; }
.contact-us form { background: #131519; padding: 30px; border-radius: 30px; }
.contact-form .section-field { position: relative; width: 100%; float: left; }
.contact-form .section-field input { position: relative; width: 100%; margin-bottom: 20px; border-radius: 90px; padding-left: 20px; height: 48px; line-height: 48px; clear: both; color: #fff; border: 1px solid #202228; background: #202228; }
.contact-form .section-field input:focus, .contact-form .section-field.textarea textarea:focus { border: 1px solid #f9b707; background: #f9b707; color: #fff; box-shadow: none; outline: none; }
.contact-form .section-field.textarea textarea { width: 100%; float: left; color: #fff; border: 1px solid #202228; border-radius: 30px; padding: 15px 0 0 20px; resize: none; background: #202228; }
#success { display: none; margin-top: 10px; }
.contact-form .section-field input::-webkit-input-placeholder, .contact-form .section-field.textarea textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
color: #fff; }
.contact-form .section-field input::-moz-placeholder, .contact-form .section-field.textarea textarea::-moz-placeholder { /* Firefox 19+ */
color: #fff; }
.contact-form .section-field input:-ms-input-placeholder, .contact-form .section-field.textarea textarea:-ms-input-placeholder { /* IE 10+ */
color: #fff; }
.contact-form .section-field input:-moz-placeholder, .contact-form .section-field.textarea textarea:-moz-placeholder { /* Firefox 18- */
color: #fff; }
.iq-contact-box-01 .iq-icon { float: left; border-radius: 90px; height: 70px; width: 70px; line-height: 70px; text-align: center; margin-right: 15px; color: #ffffff; font-size: 38px; }
.iq-contact-box-01 .iq-icon span { font-size: 30px; }
.iq-contact-box-01 .contact-content { display: table-cell; }
.iq-contact-box-01 .contact-content h5 { font-family: 'Comfortaa', cursive; }

/*Social Media*/
.info-share { margin: 40px 0 0; padding: 0; }
.info-share li { display: inline-block; list-style: none; padding: 0; margin: 0px 4px; }
.info-share li a { display: block; width: 45px; height: 45px; 
  line-height: 45px; font-size: 16px; color: #fff; 
  background: transparent; 
  text-align: center; border-radius: 50%; }

.successMalilMessage{
  font-size: 16px;
  color: yellow;
  
}
.nav-link{
  color: white !important;
}
.navbar-nav .nav-link{
  padding-left: 7px;
}
/*---------------------------------------------------------------------
                             Owl Carousel
-----------------------------------------------------------------------*/
.owl-carousel .owl-nav { display: block; position: absolute; text-indent: inherit; top: 50%; transform: translateY(-50%); -webkit-transform: translateY(-50%); -o-transform: translateY(-50%); -ms-transform: translateY(-50%); -moz-transform: translateY(-50%); left: 0; width: 100%; cursor: pointer; z-index: 999; }
.owl-carousel .owl-nav .owl-prev { display: block; position: absolute; text-align: center; text-indent: inherit; left: -8%; width: auto; cursor: pointer; -webkit-transition: opacity 0.3s ease 0s, left 0.3s ease 0s; -moz-transition: opacity 0.3s ease 0s, left 0.3s ease 0s; -ms-transition: opacity 0.3s ease 0s, left 0.3s ease 0s; -o-transition: opacity 0.3s ease 0s, left 0.3s ease 0s; transition: opacity 0.3s ease 0s, left 0.3s ease 0s; }
.owl-carousel .owl-nav .owl-next { display: block; position: absolute; text-align: center; text-indent: inherit; right: -8%; width: auto; cursor: pointer; -webkit-transition: opacity 0.3s ease 0s, right 0.3s ease 0s; -moz-transition: opacity 0.3s ease 0s, right 0.3s ease 0s; -ms-transition: opacity 0.3s ease 0s, right 0.3s ease 0s; -o-transition: opacity 0.3s ease 0s, right 0.3s ease 0s; transition: opacity 0.3s ease 0s, right 0.3s ease 0s; }
.owl-carousel .owl-nav i { font-size: 18px; border-radius: 50%; width: 44px; height: 44px; line-height: 46px; padding-left: 0px; display: inline-block; color: #fff; background: #202329; font-weight: normal; text-align: center; -webkit-transition: all 0.5s ease-out 0s; -moz-transition: all 0.5s ease-out 0s; -ms-transition: all 0.5s ease-out 0s; -o-transition: all 0.5s ease-out 0s; transition: all 0.5s ease-out 0s; }
.owl-carousel .owl-nav i:hover { background: #f9b707; color: #fff; }

/* Dots */
.owl-carousel .owl-controls .owl-dot { margin-top: 20px; display: inline-block; }
.owl-carousel .owl-dots { position: relative; width: 100%; display: inline-block; text-indent: inherit; text-align: center; cursor: pointer; }
.owl-carousel.owl-theme .owl-dots .owl-dot span { background: #23292c; display: inline-block; border-radius: 30px; margin: 0px 3px; height: 10px; width: 10px; border: 1px solid #23292c; transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; cursor: pointer; }
.owl-carousel.owl-theme .owl-dots .owl-dot:hover span { background: #f9b707; border: 1px solid #f9b707; }
.owl-carousel.owl-theme .owl-dots .owl-dot.active span { background: #f9b707; border: 1px solid #f9b707; }

/*************************
      About
*************************/
.iq-about ul li { width: 32%; display: inline-block; }
.iq-about ul li img { float: left; }
.iq-about ul li span { display: table; font-weight: bold; line-height: normal; margin: 10px; padding-left: 10px; }
.iq-about ul li span b { display: block; font-size: 18px; line-height: normal; margin-bottom: 0px; }

/*Pricing Small*/
.iq-small-icon { text-align: center; padding: 30px 10px; margin-bottom: 20px; }
.iq-small-icon span { display: inline-block; width: 100%; }
.iq-small-icon h3 { position: relative; margin-bottom: 10px; }
.iq-small-icon h3:before { position: absolute; content: ""; bottom: -10px; left: 50%; width: 50px; margin-left: -25px; background: #f9b707; height: 2px; }
.iq-small-icon { background: rgba(19, 21, 25, 0.5); border-radius: 5px; color: #fff; }

/*---------------------------------------------------------------------
                             Fancy-box 
---------------------------------------------------------------------*/
.iq-fancy-box-1 { padding: 0 20px 20px; margin-bottom: 120px; position: relative; }
.iq-fancy-box-1 .fancy-icon img { width: 60%; margin-top: -80px; }
.iq-fancy-box-1 .fancy-content h5 { font-family: 'Comfortaa', cursive; margin: 25px 0 25px; }
.iq-video { position: absolute; left: 50%; top: 50%; background: #fff; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); width: 80px; height: 80px; text-align: center; font-size: 38px; color: #f9b707; border-radius: 100%; line-height: 2.1; z-index: 9; }
.iq-video i { margin-left: 7px; }
.iq-first-adv { background: rgba(249, 183, 7, 0.4); }

/*---------------------------------------------------------------------
                          Timeline
-----------------------------------------------------------------------*/
.timeline-1 h6 { color: #fff; }
.timeline-1 { overflow: hidden; position: relative; }
.timeline-1:before { content: ""; width: 3px; height: 100%; background: #f2f2f2; position: absolute; top: 0; left: 50%; }
.timeline-1 .timeline-t { padding-right: 30px; position: relative; }
.timeline-1 .timeline-t:before, .timeline-1 .timeline-t:after { content: ""; display: block; width: 100%; clear: both; }
.timeline-1 .timeline-t:first-child:before, .timeline-1 .timeline-t:last-child:before { content: ""; width: 13px; height: 13px; border-radius: 50%; border: 2px solid #f7f7f7; background: #f9b707; margin: 0 auto; position: absolute; top: 0; left: 0; right: -3px; }
.timeline-1 .timeline-t:last-child:before { top: auto; bottom: 0; }
.timeline-1 .timeline-icon { width: 18px; height: 18px; border-radius: 50%; background: #f9b707; border: 2px solid #131418; box-sizing: content-box; margin: auto; position: absolute; top: 0; left: 0; bottom: 0; right: -4px; }
.timeline-1 .timeline-icon:before { content: ""; display: block; width: 8px; height: 8px; border-radius: 50%; background: #131418; margin: auto; position: absolute; top: 0; left: 0; bottom: 0; right: 0; }
.timeline-1 .year { display: inline-block; padding: 8px 20px; margin: 0; font-size: 14px; color: #fff; background: #131418; text-align: center; position: absolute; top: 50%; right: 35%; transform: translateY(-50%); }
.timeline-1 .year:before { content: ""; border-right: 18px solid #131418; border-top: 20px solid transparent; border-bottom: 20px solid transparent; position: absolute; top: 0; left: -18px; }
.timeline-1 .timeline-content { border-radius: 3px; width: 46.5%; padding: 30px; margin: 0 20px 0 0; background: #131418; position: relative; }
.timeline-1 .timeline-content:after { content: ""; border-left: 20px solid #131418; border-top: 20px solid transparent; border-bottom: 20px solid transparent; position: absolute; top: 50%; right: -20px; transform: translateY(-50%); }
.timeline-1 .timeline-t:nth-child(2n) { padding: 0 0 0 30px; }
.timeline-1 .timeline-t:nth-child(2n) .year { right: auto; left: 35%; }
.timeline-1 .timeline-t:nth-child(2n) .year:before { border: 20px solid transparent; border-right: none; border-left: 18px solid #131418; left: auto; right: -18px; }
.timeline-1 .timeline-t:nth-child(2n) .timeline-content { float: right; margin: 0 0 0 20px; }
.timeline-1 .timeline-t:nth-child(2n) .timeline-content:after { border-left: none; border-right: 20px solid #131418; right: auto; left: -20px; }
@media only screen and (max-width:1200px) {
  .timeline-1 .year { right: 30%; }
  .timeline-1 .timeline-t:nth-child(2n) .year { left: 30%; }
}
@media only screen and (max-width:990px) {
  .timeline-1 .year { right: 25%; }
  .timeline-1 .timeline-t:nth-child(2n) .year { left: 25%; }
}
@media only screen and (max-width:767px) {
  .timeline-1:before { left: 10px; }
  .timeline-1 .timeline-t { padding: 0 0 0 30px; margin-bottom: 20px; }
  .timeline-1 .timeline-t:last-child { margin-bottom: 0; }
  .timeline-1 .timeline-t:first-child:before, .timeline-1 .timeline-t:last-child:before { display: none; }
  .timeline-1 .timeline-icon { margin: 0; position: absolute; top: 7px; left: 0; }
  .timeline-1 .year, .timeline-1 .timeline-t:nth-child(2n) .year { display: block; font-weight: bold; margin: 0 0 32px 30px; z-index: 1; position: relative; top: auto; left: auto; right: auto; transform: none; }
  .timeline-1 .timeline-t:nth-child(2n) .year:before { border-left: none; border-right: 18px solid #131418; right: auto; left: -18px; }
  .timeline-1 .timeline-content { padding: 20px; }
  .timeline-1 .timeline-content, .timeline-1 .timeline-t:nth-child(2n) .timeline-content { width: auto; float: none; margin: 0 0 0 30px; }
  .timeline-1 .timeline-content:after, .timeline-1 .timeline-t:nth-child(2n) .timeline-content:after { border: none; border-bottom: 20px solid #131418; border-left: 20px solid transparent; border-right: 20px solid transparent; top: -20px; left: 50%; right: auto; transform: translateX(-50%); }
}
@media only screen and (max-width:480px) {
  .timeline-1 .year, .timeline-1 .timeline-t:nth-child(2n) .year { margin-left: 20px; }
  .timeline-1 .timeline-content, .timeline-1 .timeline-t:nth-child(2n) .timeline-content { margin-left: 10px; }
}

/*************************
Footer 2
*************************/
.iq-footer1 img { width: 150px; }

/*Contact*/
.iq-footer1 .iq-contact-box .iq-icon { float: left; text-align: center; margin-right: 15px; color: #4cbb20; }
.iq-footer1 .iq-contact-box .iq-icon i { font-size: 40px; }
.iq-footer1 .iq-contact-box .iq-content { display: table-cell; }
.iq-footer1 .iq-contact-box .iq-lead { color: #fff; }

/*About-us*/
.iq-footer1 .about-us li { margin: 15px 0; }
.iq-footer1 .about-us li a { color: #fff; }
.iq-footer1 .about-us li a:hover { color: #4cbb20; }

/*Newsletter*/
.iq-footer1 .iq-newsletter .form-control {
   background: #fff; -webkit-border-radius: 3px 0 0 3px;
    -moz-border-radius: 3px 0 0 3px; border-radius: 3px 0 0 3px; 
    height: 50px; display: inline-block; padding-left: 25px; box-shadow: none;
     order: none; float: left; width: 60%; 
     margin: 2px;
    }
.iq-footer1 .iq-newsletter .button { -webkit-border-radius: 0px 3px 3px 0px; -moz-border-radius: 0px 3px 3px 0px; border-radius: 0px 3px 3px 0px; }

/*---------------------------------------------------------------------
                          Footer
-----------------------------------------------------------------------*/
.iq-footer .logo img { width: 200px; }
.iq-footer .small-title { margin-bottom: 20px; }
.iq-footer hr { border-bottom: 1px solid rgba(255, 255, 255, 0.2); }

/*Menu*/
.iq-footer .footer-menu li { margin: 0; }
.iq-footer .footer-menu li a { font-weight: 500; background: rgba(255, 255, 255, 0); padding: 8px 0; display: block; color: #ffffff; }
.iq-footer .footer-menu li a:hover { background: rgba(255, 255, 255, 0.2); color: #f9b707; padding: 8px 15px; }

/*Copyright*/
.iq-footer .iq-copyright a { color: #f9b707; }
.iq-footer .iq-copyright a:hover { color: #ffffff; }
.iq-footer .footer-bottom { background: #000000; }

/*Social Media*/
.iq-footer .iq-media-blog { margin: 0; padding: 0; text-align: right; }
.iq-footer .iq-media-blog li { display: inline-block; list-style: none; padding: 0; margin: 0px 4px; }
.iq-footer .iq-media-blog li a { display: block; width: 45px; height: 45px; line-height: 45px; font-size: 16px; color: #ffffff; background: transparent; border: 1px solid #666666; text-align: center; border-radius: 50%; }
.iq-footer .iq-media-blog li a:hover { background: #f9b707; border-color: #f9b707; color: #fff; }

/*Contact*/
.iq-footer .iq-contact .blog { color: #ffffff; }
.iq-footer .iq-contact .blog .title { font-size: 20px; }
.iq-footer .iq-contact .blog span { font-size: 20px; }
.iq-footer .iq-contact .blog i { color: #f9b707; font-size: 40px; line-height: 40px; vertical-align: middle; margin-right: 15px; display: table; float: left; }
.iq-footer .iq-contact .blog .content { display: table-cell; }
.iq-footer .call-back { background: rgba(0, 0, 0, 0.2); padding: 30px; }
.iq-footer .call-back .form-control { border: none; border-radius: 0; height: 45px !important; }

/*************************************
Feature
**************************************/
.iq-feature1 .left { width: 76px; height: 76px; float: left; margin-right: 20px; text-align: center; border: 1px solid #f9b707; transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; }
.iq-feature1 .left i { color: #f9b707; font-size: 30px; background: #f3f3f3; display: block; margin: 7px 10px 10px 7px; width: 60px; height: 60px; line-height: 60px; transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; }
.iq-feature1 .right { display: table-cell; margin-top: 10px; vertical-align: top; }
.iq-feature1 .right p { margin-top: 0px; }
.iq-feature1:hover .left, .iq-feature1:hover .left i { border-color: #f9b707; }
.iq-feature1:hover .left i { background: #f9b707; color: #ffffff; }
.iq-feature1 .brd { -webkit-border-radius: 100px; -moz-border-radius: 100px; border-radius: 100px; }
.right-side .iq-feature1 .left { float: right; margin-left: 20px; margin-right: 0; }
.right-side .iq-feature1 .right { text-align: right; }

/* Multi Color Icons */
.iq-feature1 .left.big { width: 89px; height: 89px; }
.iq-feature1 .left i.in-bg-1 { background: #64c5ae; color: #ffffff; width: 74px; height: 74px; line-height: 74px; }
.iq-feature1 .left i.in-bg-2 { background: #f5c795; color: #ffffff; width: 74px; height: 74px; line-height: 74px; }
.iq-feature1 .left i.in-bg-3 { background: #b4aaec; color: #ffffff; width: 74px; height: 74px; line-height: 74px; }
.iq-feature1 .left.in-brd-1, .iq-feature1 .left.in-brd-2, .iq-feature1 .left.in-brd-3 { border: 1px solid #f9b707; }
.iq-feature1:hover .left.in-brd-1 { border: 1px solid #64c5ae; }
.iq-feature1:hover .left.in-brd-2 { border: 1px solid #f5c795; }
.iq-feature1:hover .left.in-brd-3 { border: 1px solid #b4aaec; }